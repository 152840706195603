import React from 'react';
import { useTranslation } from 'react-i18next';
import TokenDistribution from './TokenDistribution';

const MigrationBanner = () => {
  const { t } = useTranslation();

  const styles = {
    wrapper: {
      padding: '40px 20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(180deg, rgba(22, 27, 34, 0) 0%, rgba(22, 27, 34, 0.3) 50%, rgba(22, 27, 34, 0) 100%)',
    },
    migrationBanner: {
      background: 'rgba(22, 27, 34, 0.7)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      padding: '24px',
      border: '1px solid rgba(59, 130, 246, 0.1)',
      maxWidth: '900px',
      width: '100%'
    },
    migrationContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '24px',
      marginBottom: '20px',
      '@media (max-width: 600px)': {
        flexDirection: 'column',
        gap: '16px'
      }
    },
    migrationBox: {
      background: 'rgba(255, 255, 255, 0.03)',
      borderRadius: '12px',
      padding: '16px 24px',
      textAlign: 'center',
      minWidth: '180px'
    },
    migrationLabel: {
      color: '#94a3b8',
      marginBottom: '8px',
      fontSize: '0.9rem',
      fontWeight: '500'
    },
    migrationValue: {
      color: '#e2e8f0',
      fontSize: '1.2rem',
      fontWeight: '600'
    },
    arrow: {
      color: '#3b82f6',
      fontSize: '24px',
      '@media (max-width: 600px)': {
        transform: 'rotate(90deg)'
      }
    },
    migrationNote: {
      textAlign: 'center',
      color: '#94a3b8',
      fontSize: '0.95rem',
      lineHeight: '1.6',
      margin: '0'
    }
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.migrationBanner} data-aos="fade-up">
        <div style={styles.migrationContent}>
          <div style={styles.migrationBox}>
            <div style={styles.migrationLabel}>{t('migrationBanner.oldTokenSupplyLabel')}</div>
            <div style={styles.migrationValue}>{t('migrationBanner.oldTokenSupplyValue')}</div>
          </div>
          <div style={styles.arrow}>➜</div>
          <div style={styles.migrationBox}>
            <div style={styles.migrationLabel}>{t('migrationBanner.newTokenSupplyLabel')}</div>
            <div style={styles.migrationValue}>{t('migrationBanner.newTokenSupplyValue')}</div>
          </div>
        </div>
        <p style={styles.migrationNote}>
          {t('migrationBanner.migrationNote')}
        </p>
      </div>
    </div>
  );
};

const PartnersSection = () => {
  const { t } = useTranslation();

  return (
    <section className="partners">
      <br></br>
      <div className="container">
        <h2 className="partners__title">{t('nav.partners')}</h2>
        <div className="partners__logos">
          <div className="partners__logo">
            <a 
              href="https://www.dextools.io/app/en/ether/pair-explorer/0xf716d309df919a2a22e3e4da19ff018f2f9b71e5?t=1729658005746" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img 
                src="https://cdn.prod.website-files.com/6597cc7be68d63ec0c8ce33f/65b652c77b3c487c2a45ab01_DEXTools__white.webp" 
                alt={t('partners.dextools_logo')} 
              />
            </a>
          </div>
          <div className="partners__logo">
            <a 
              href="https://app.uniswap.org/explore/tokens/ethereum/0x5f25003218bf63ab578d918ab12ac1bf60e49c41" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img 
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Uniswap_Logo_and_Wordmark.svg/1280px-Uniswap_Logo_and_Wordmark.svg.png" 
                alt={t('partners.uniswap_logo')} 
              />
            </a>
          </div>
          <div className="partners__logo">
            <a 
              href="https://etherscan.io/token/0x5f25003218bf63ab578d918ab12ac1bf60e49c41" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <img 
                src="https://etherscan.io/assets/svg/logos/logo-etherscan.svg?v=0.0.2" 
                alt={t('partners.etherscan_logo')} 
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const FeatureSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <MigrationBanner />
      <PartnersSection />
      <TokenDistribution />
      <section className="feature">
        <div className="feature__background feature__background--pattern">
          <img 
            className="feature__pattern-bg" 
            src="../assets/media/images/landing-page-v2/landing-page-v2-feature-pattern.png" 
            alt={t('feature.background_pattern')} 
          />
        </div>
        <div className="feature__background--mobile feature__background--pattern">
          <img 
            className="feature__pattern-bg" 
            src="../assets/media/images/landing-page-v2/mobile-landing-page-v2-feature-pattern.png" 
            alt={t('feature.background_pattern_mobile')} 
          />
        </div>
        <div className="container">
          <div className="feature__container">
            <div className="feature__group">
              <div className="feature__items feature__items--1" data-aos="zoom-in-up" data-aos-delay="100">
                <div className="feature-items__img">
                  <img 
                    src="../assets/media/images/icons/icon-portofolio-manager.svg" 
                    alt={t('feature.portfolio_manager_alt')} 
                  />
                </div>
                <h5 className="feature-items__title">{t('feature.portfolio_manager_title')}</h5>
                <p className="fb-regular feature-items__desc">{t('feature.portfolio_manager_desc')}</p>
              </div>
              <div className="feature__items feature__items--2" data-aos="zoom-in-up" data-aos-delay="200">
                <div className="feature-items__img">
                  <img 
                    src="../assets/media/images/icons/phone.svg" 
                    alt={t('feature.mobile_apps_alt')} 
                  />
                </div>
                <h5 className="feature-items__title">{t('feature.mobile_apps_title')}</h5>
                <p className="fb-regular feature-items__desc">{t('feature.mobile_apps_desc')}</p>
              </div>
              <div className="feature__items feature__items--3" data-aos="zoom-in-up" data-aos-delay="300">
                <div className="feature-items__img">
                  <img 
                    src="../assets/media/images/icons/lock.svg" 
                    alt={t('feature.vault_protection_alt')} 
                  />
                </div>
                <h5 className="feature-items__title">{t('feature.vault_protection_title')}</h5>
                <p className="fb-regular feature-items__desc">{t('feature.vault_protection_desc')}</p>
              </div>
            </div>
            <div className="feature__content" data-aos="fade-up" data-aos-duration="500">
              <h2 className="feature__title">{t('feature.trusted_platform_title')}</h2>
              <p className="fb-regular feature__subtitle">{t('feature.trusted_platform_subtitle')}</p>
              <a 
                href="https://www.dextools.io/app/en/ether/pair-explorer/0xf716d309df919a2a22e3e4da19ff018f2f9b71e5?t=1729658005746" 
                className="btn btn-primary btn-pill feature__button"
              >
                {t('feature.trade_now_button')}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureSection;
