// src/App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import FeatureSection from './components/FeatureSection';
import ExchangeSection from './components/ExchangeSection';
import WhyChooseUsSection from './components/WhyChooseUsSection';
import TestimonialSection from './components/TestimonialSection';
import CTASection from './components/CTASection';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Roadmap from './pages/Roadmap';
import Token from './pages/Token';
import Whitepaper from './pages/Whitepaper'; // Import the new Whitepaper component
import './i18n';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <HeroSection />
                <FeatureSection />
                <WhyChooseUsSection />
                <CTASection />
              </>
            } 
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/token" element={<Token />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/whitepaper" element={<Whitepaper />} /> {/* New route for Whitepaper */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
