import React, { useState } from 'react';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';

const ExchangeSection = () => {
  const { t } = useTranslation();
  const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(false);
  const [account, setAccount] = useState(null);
  const [currency, setCurrency] = useState('ETH');
  const [ethAmount, setEthAmount] = useState(0);
  const [usdtAmount, setUsdtAmount] = useState(0);
  const [wpiTokens, setWpiTokens] = useState(0);

  const WPI_TOKEN_PRICE_USD = 0.0001;
  const WPI_TOKEN_PRICE_ETH = 4.0e-8;
  const presaleAddress = '0xf19D98f0d6797386E1D235492ebE8dd3bEE2ecD6';

  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        setIsMetaMaskConnected(true);
      } catch (error) {
        console.error(t("exchange.metamask_connection_failed"), error);
      }
    } else {
      alert(t("exchange.install_metamask"));
    }
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
    setEthAmount(0);
    setUsdtAmount(0);
    setWpiTokens(0);
  };

  const handleEthAmountChange = (e) => {
    const eth = e.target.value;
    setEthAmount(eth);
    const wpi = eth / WPI_TOKEN_PRICE_ETH;
    setWpiTokens(wpi);
  };

  const handleUsdtAmountChange = (e) => {
    const usdt = e.target.value;
    setUsdtAmount(usdt);
    const wpi = usdt / WPI_TOKEN_PRICE_USD;
    setWpiTokens(wpi);
  };

  const participateInPresale = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      if (currency === 'ETH') {
        const ethAmountInWei = web3.utils.toWei(ethAmount.toString(), 'ether');
        try {
          await web3.eth.sendTransaction({
            from: account,
            to: presaleAddress,
            value: ethAmountInWei,
          });
          alert(t("exchange.transaction_success", { ethAmount }));
        } catch (error) {
          console.error(t("exchange.transaction_failed"), error);
        }
      } else if (currency === 'USDT') {
        alert(t("exchange.usdt_not_implemented"));
      }
    }
  };

  return (
    <section className="exchange">
      <div className="exchange__background exchange__background--pattern">
        <img
          className="exchange__pattern-bg"
          src="../assets/media/images/landing-page-v2/landing-page-v2-exchange-pattern.png"
          alt={t("exchange.background_pattern")}
        />
      </div>
      <div className="container">
        <div className="exchange__container">
          <div className="exchange__content">
            <h2 className="exchange__title" data-aos="fade-up">
              {t("exchange.title")}
            </h2>
            <p className="fb-regular exchange__subtitle" data-aos="fade-up" data-aos-delay="100">
              {t("exchange.subtitle")}
            </p>
            <div className="exchange__groups">
              {!isMetaMaskConnected ? (
                <div className="exchange__items exchange__items--1" data-aos="fade-right" data-aos-delay="300">
                  <button className="btn btn-primary" onClick={connectMetaMask}>
                    {t("exchange.connect_metamask")}
                  </button>
                </div>
              ) : (
                <>
                  <div className="presale-card presale-card--v2" data-aos="zoom-in-up" data-aos-delay="600" style={{ textAlign: 'right' }}>
                    <div className="presale-card__container">
                      <div className="presale-card__description">
                        <p>{t("exchange.select_currency")}</p>
                        <select value={currency} onChange={handleCurrencyChange} className="form-select">
                          <option value="ETH">ETH</option>
                          <option value="USDT">USDT</option>
                        </select>
                        {currency === 'ETH' ? (
                          <p>{t("exchange.price_per_token_eth", { price: "4.0e-8 ETH" })}</p>
                        ) : (
                          <p>{t("exchange.price_per_token_usdt", { price: "$0.0001" })}</p>
                        )}
                      </div>
                      <div className="tab-content" id="presaleTabContent">
                        <div className="tab-pane fade show active" id="presale-tab-pane" role="tabpanel" aria-labelledby="presale-tab" tabIndex="0">
                          <div className="presale-card__detail">
                            <p className="fb-regular notes">{t("exchange.presale_amount")}</p>
                          </div>
                          <div className="presale-card__forms">
                            {currency === 'ETH' ? (
                              <div className="forms-purchase__box">
                                <div className="forms-group">
                                  <label htmlFor="ethInput" className="form-label">{t("exchange.enter_eth")}</label>
                                  <input
                                    type="number"
                                    id="ethInput"
                                    min="0"
                                    value={ethAmount}
                                    onChange={handleEthAmountChange}
                                    className="form-control"
                                    placeholder="e.g., 0.1 ETH"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="forms-purchase__box">
                                <div className="forms-group">
                                  <label htmlFor="usdtInput" className="form-label">{t("exchange.enter_usdt")}</label>
                                  <input
                                    type="number"
                                    id="usdtInput"
                                    min="0"
                                    value={usdtAmount}
                                    onChange={handleUsdtAmountChange}
                                    className="form-control"
                                    placeholder="e.g., 10 USDT"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="forms-purchase__box">
                              <div className="forms-group">
                                <label htmlFor="wpiOutput" className="form-label">{t("exchange.equivalent_tokens")}</label>
                                <input
                                  type="number"
                                  id="wpiOutput"
                                  value={wpiTokens}
                                  readOnly
                                  className="form-control"
                                  placeholder={t("exchange.display_after_amount")}
                                />
                              </div>
                            </div>
                            <button className="btn btn-primary presale-card__button" onClick={participateInPresale}>
                              {t("exchange.join_presale")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExchangeSection;
