import React, { useState } from 'react';

const topCoins = [
  "Bitcoin (BTC)", "Walletpayin (WPI)", "Ethereum (ETH)", "Binance Coin (BNB)", "Solana (SOL)", "Cardano (ADA)",
  "XRP (XRP)", "Polkadot (DOT)", "Avalanche (AVAX)", "Terra (LUNA)", "Dogecoin (DOGE)", "Chainlink (LINK)",
  "Litecoin (LTC)", "Bitcoin Cash (BCH)", "Stellar (XLM)", "Cosmos (ATOM)", "Polygon (MATIC)", "Tezos (XTZ)",
  "Algorand (ALGO)", "Filecoin (FIL)", "TRON (TRX)", "NEO (NEO)", "Ethereum Classic (ETC)", "VeChain (VET)"
];

const environmentalOptions = ["Mobile app", "Website", "N/A"];
const issueTypes = [
  "Buy/Sell Crypto", "Others", "Swap", "Partnership", "Affiliate program", "Ambassador program",
  "Set/Restore/Configure Wallet", "Staking", "Transaction", "Wallet Balance"
];

function Contact() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "", subject: "", environmental: "", coinToken: "", issueType: "", description: "",
    file: null, supportId: "", metamaskAddress: "", gdprChecked: false, signature: null
  });
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 700 * 1024) {
      setMessage("The file is too large. Maximum size is 700 KB.");
      setMessageType('danger');
      setTimeout(() => { setMessage(null); setMessageType(null); }, 2000);
      e.target.value = null;
    } else {
      setFormData({ ...formData, file });
    }
  };

  const connectMetamask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const metamaskAddress = accounts[0];
        const supportId = metamaskAddress.slice(24, 32);
        setFormData({ ...formData, metamaskAddress, supportId });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const signMessage = async () => {
    if (window.ethereum && formData.metamaskAddress) {
      try {
        return await window.ethereum.request({
          method: 'personal_sign',
          params: [window.ethereum.selectedAddress,
            `Do you want to continue sending a message to Walletpayin support? Sign support ID: ${formData.supportId}`]
        });
      } catch (error) {
        console.error(error);
        return null;
      }
    }
    return null;
  };

  const sendEmail = async (data) => {
    try {
      const response = await fetch('https://mail.walletpayin.com/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      return response.status === 200;
    } catch (error) {
      console.error('Error sending email:', error);
      return false;
    }
  };

  const handleConfirm = async () => {
    const signature = await signMessage();
    if (signature) {
      setFormData({ ...formData, signature });
      const emailSent = await sendEmail(formData);

      if (emailSent) {
        setMessage("Successfully sent!");
        setMessageType('success');
      } else {
        setMessage("Sending failed. Please try again.");
        setMessageType('danger');
      }

      setFormData({
        email: "", subject: "", environmental: "", coinToken: "", issueType: "", description: "",
        file: null, metamaskAddress: "", gdprChecked: false, supportId: "", signature: null
      });
      setStep(1);
      setTimeout(() => { setMessage(null); }, 3000);
    } else {
      setMessage("Error signing the message. Please try again.");
      setMessageType('danger');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.gdprChecked) {
      setMessage("Please accept GDPR terms.");
      setMessageType('danger');
      return;
    }

    if (step === 1) {
      await connectMetamask();
      setStep(2);
    } else if (step === 2) {
      const confirmed = window.confirm("Do you want to send the support message?");
      if (confirmed) handleConfirm();
    }
  };

  return (
    <section className="contact">
      <div className="container d-flex flex-column align-items-center justify-content-center min-vh-100 mt-5">
        <h1 className="ls-tight font-bolder display-3 mb-3 text-center text-white">Contact Support</h1>
        {message && (
          <p className={`alert alert-${messageType} mt-3`} role="alert">
            {message}
          </p>
        )}
        <div className="col-md-8">
          <form onSubmit={handleSubmit}>
            {step === 1 && (
              <>
                {["email", "subject"].map((field, index) => (
                  <div className="form-outline mb-4 d-flex flex-column" key={index}>
                    <label className="form-label text-left text-white" htmlFor={field}>{field === "email" ? "Email" : "Subject"}*</label>
                    <input
                      type={field === "email" ? "email" : "text"}
                      id={field}
                      className="form-control"
                      required
                      value={formData[field]}
                      onChange={(e) => setFormData({ ...formData, [field]: e.target.value })}
                    />
                  </div>
                ))}
                {[
                  ["environmental", environmentalOptions, "Environment"],
                  ["coinToken", topCoins, "Coin/Token"],
                  ["issueType", issueTypes, "Issue Type"]
                ].map(([id, options, label], index) => (
                  <div className="form-outline mb-4 d-flex flex-column" key={index}>
                    <label className="form-label text-left text-white" htmlFor={id}>{label}*</label>
                    <select
                      id={id}
                      className="form-select"
                      required
                      value={formData[id]}
                      onChange={(e) => setFormData({ ...formData, [id]: e.target.value })}
                    >
                      <option value="">Select {label}</option>
                      {options.map((option, idx) => (
                        <option key={idx} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                ))}
                <div className="form-outline mb-4 d-flex flex-column">
                  <label className="form-label text-left text-white" htmlFor="description">Description*</label>
                  <textarea
                    className="form-control"
                    id="description"
                    rows="4"
                    required
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  ></textarea>
                </div>
                <div className="form-outline mb-4 d-flex flex-column">
                  <label className="form-label text-left text-white" htmlFor="file">Attach File</label>
                  <input
                    type="file"
                    id="file"
                    accept=".pdf, .doc, .docx"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="form-check mb-4 d-flex">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="gdprCheck"
                    checked={formData.gdprChecked}
                    onChange={() => setFormData({ ...formData, gdprChecked: !formData.gdprChecked })}
                  />
                  <label className="form-check-label text-white" htmlFor="gdprCheck">
                    I agree to the <a href="https://walletpayin.com/terms" target="_blank" rel="noopener noreferrer">terms and conditions</a>.
                  </label>
                </div>
                <button type="submit" className="btn btn-primary">Next</button>
              </>
            )}
            {step === 2 && (
              <div className="container-lg max-w-screen-xl d-flex justify-content-center">
                <div className="col-md-8">
                  <div className="recap-card text-white border rounded p-4 mt-4" style={{ background: "#343a40" }}>
                    <h3 className="text-center font-weight-bold mb-4">Recapitulation</h3>
                    <p><strong>Support ID:</strong> {formData.supportId}</p>
                    <p><strong>Metamask Address:</strong> {formData.metamaskAddress}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Subject:</strong> {formData.subject}</p>
                    <p><strong>Environment:</strong> {formData.environmental}</p>
                    <p><strong>Coin/Token:</strong> {formData.coinToken}</p>
                    <p><strong>Issue Type:</strong> {formData.issueType}</p>
                    <p><strong>Description:</strong> {formData.description}</p>
                    <div className="d-flex mt-4 justify-content-between">
                      <button type="button" className="btn btn-primary" onClick={handleConfirm}>Submit</button>
                      <button type="button" className="btn btn-secondary" onClick={() => setStep(1)}>Edit</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
