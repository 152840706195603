import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';

const TokenDistribution = () => {
  const { t } = useTranslation();

  const data = [
    { name: t('allocation.publicSale'), value: 20 },
    { name: t('allocation.stakingRewards'), value: 20 },
    { name: t('allocation.liquidity'), value: 10 },
    { name: t('allocation.launchpadRound'), value: 13 },
    { name: t('allocation.communityRound'), value: 10 },
    { name: t('allocation.marketingAirdrop'), value: 10 },
    { name: t('allocation.team'), value: 9 },
    { name: t('allocation.foundersRound'), value: 7 },
    { name: t('allocation.advisors'), value: 1 }
  ];
  
  

  const COLORS = [
    '#4DA8DA',  // Jemná modrá pro Public Sale
    '#50B8C0',  // Tyrkysová pro Staking Rewards
    '#6AC1B8',  // Světle tyrkysová pro Liquidity
    '#9FADE7',  // Světle fialová pro Launchpad Round
    '#81D4F9',  // Světle modrá pro Community Round
    '#A8CFFF',  // Světlejší modrá pro Marketing & Airdrop
    '#6679B7',  // Modrofialová pro Team
    '#5571A8',  // Tmavší modrá pro Founders Round
    '#394D7E',  // Tmavě modrá pro Advisors
    '#8FE2E7',  // Světle tyrkysová
    '#5CBDDC',  // Středně modrá
    '#68B6D2',  // Modrozelená
    '#4C8AB6',  // Modrofialová
    '#516FC7',  // Fialová s nádechem do modra
    '#365BC9',  // Tmavá královská modrá
    '#1F4BCB'   // Tmavě modrá
  ];

  const renderCustomLabel = ({ cx, cy, midAngle, outerRadius, value, name }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius * 1.32;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const lineEndRadius = outerRadius * 1.2;
    const lineEndX = cx + lineEndRadius * Math.cos(-midAngle * RADIAN);
    const lineEndY = cy + lineEndRadius * Math.sin(-midAngle * RADIAN);

    const textAnchor = x > cx ? 'start' : 'end';

    return (
      <g>
        <line
          x1={cx + (outerRadius + 5) * Math.cos(-midAngle * RADIAN)}
          y1={cy + (outerRadius + 5) * Math.sin(-midAngle * RADIAN)}
          x2={lineEndX}
          y2={lineEndY}
          stroke="rgba(255, 255, 255, 0.3)"
          strokeWidth={1}
        />
        <text
          x={x}
          y={y}
          fill="#fff"
          textAnchor={textAnchor}
          style={{ 
            fontSize: '16px',
            fontWeight: '500',
            fontFamily: 'Inter, sans-serif',
            userSelect: 'none',  // Přidáno pro zabránění označení textu
            outline: 'none'      // Přidáno pro odstranění outlines
          }}
        >
          {name}
        </text>
        <text
          x={x}
          y={y + 20}
          fill="rgba(255, 255, 255, 0.7)"
          textAnchor={textAnchor}
          style={{ 
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'Inter, sans-serif',
            userSelect: 'none',  // Přidáno pro zabránění označení textu
            outline: 'none'      // Přidáno pro odstranění outlines
          }}
        >
          {value}%
        </text>
      </g>
    );
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px 0',
    WebkitTapHighlightColor: 'transparent', // Odstraní zvýraznění na mobilních zařízeních
    outline: 'none',                        // Odstraní outline pro celý kontejner
  };

  const descriptionStyle = {
    width: window.innerWidth <= 768 ? '100%' : '45%',
    padding: '0 20px',
    textAlign: 'left',
    marginBottom: window.innerWidth <= 768 ? '40px' : '0',
    userSelect: 'none',                     // Přidáno pro zabránění označení textu
  };

  const chartContainerStyle = {
    width: window.innerWidth <= 768 ? '100%' : '55%',
    height: window.innerWidth <= 768 ? '500px' : '600px',
    padding: '0 20px',
    position: 'relative',
    outline: 'none',                        // Odstraní outline pro graf
  };

  const titleStyle = {
    color: '#FFF',
    marginBottom: '16px',
    userSelect: 'none',                     // Přidáno pro zabránění označení textu
  };

  const descriptionTextStyle = {
    color: 'rgba(255, 255, 255, 0.7)',
    lineHeight: '1.6',
    userSelect: 'none',                     // Přidáno pro zabránění označení textu
  };

  return (
    <section style={containerStyle}>
      <div style={descriptionStyle}>
        <h2 style={titleStyle}>{t('tokenDistribution.title')}</h2>
        <p style={descriptionTextStyle}>
          {t('tokenDistribution.description')}
        </p>
      </div>

      <div style={chartContainerStyle}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius="45%"
              outerRadius="65%"
              dataKey="value"
              labelLine={false}
              label={renderCustomLabel}
              paddingAngle={2}
            >
              {data.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={COLORS[index]}
                  stroke="#000000"
                  strokeWidth={2}
                  style={{ outline: 'none' }}  // Přidáno pro odstranění outlines
                />
              ))}
            </Pie>
            
            <text
              x="50%"
              y="48%"
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                fontSize: '24px',
                fontWeight: '600',
                fill: '#FFFFFF',
                fontFamily: 'Inter, sans-serif',
                userSelect: 'none',
                outline: 'none'
              }}
            >
              {t('tokenDistribution.totalSupply')}
            </text>
            <text
              x="50%"
              y="53%"
              textAnchor="middle"
              dominantBaseline="middle"
              style={{
                fontSize: '18px',
                fontWeight: '500',
                fill: 'rgba(255, 255, 255, 0.7)',
                fontFamily: 'Inter, sans-serif',
                userSelect: 'none',
                outline: 'none'
              }}
            >
              {t('tokenDistribution.totalSupplyAmount')}
            </text>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default TokenDistribution;