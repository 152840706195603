import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__item footer-item-main" data-aos="fade-up">
            <img className="footer-item-main__logo" src="../assets/media/images/logo-full.png" alt="Walletpayin" />
            <p className="fb-lg footer-item-main__desc">{t('footer.desc')}</p>
            <div className="footer-item-main__socmed">
              <a href="https://x.com/walletpayin" className="footer-socmed">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://instagram.com/walletpayin" className="footer-socmed">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://t.me/walletpayin" className="footer-socmed">
                <i className="fab fa-telegram"></i>
              </a>
              <a href="https://www.youtube.com/@Walletpayin" className="footer-socmed">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
          <div className="footer__item footer-item-quick-links" data-aos="fade-up" data-aos-delay="100">
            <h6 className="footer__item-title">{t('footer.quick_links_title')}</h6>
            <div className="footer-quick-links">
              <a href="/contact" className="fb-md footer-quick-links__link">{t('footer.support')}</a>
              <a href="/terms" className="fb-md footer-quick-links__link">{t('footer.terms')}</a>
              <a href="/privacy" className="fb-md footer-quick-links__link">{t('footer.privacy')}</a>
            </div>
          </div>
          <div className="footer__item footer-item-updates" data-aos="fade-up" data-aos-delay="200">
            <h6 className="footer__item-title">{t('footer.subscribe_title')}</h6>
            <p className="fb-md footer-item-updates__desc">{t('footer.subscribe_desc')}</p>
            <div className="mini-form-sm__box">
              <form className="mini-form">
                <input type="text" className="form-control form-control-sm mini-form__input" placeholder={t('footer.placeholder')} autoComplete="off" />
                <input type="submit" className="btn btn-primary btn-sm mini-form__submit" value={t('footer.submit')} />
              </form>
            </div>
          </div>
        </div>
        <div className="footer__foot" data-aos="fade-up" data-aos-delay="300">
          <p className="fb-md footer-foot__copyright">Walletpayin © {new Date().getFullYear()}. {t('footer.rights_reserved')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
