import React from 'react';

function Whitepaper() {
   const styles = {
       wrapper: {
           minHeight: '100%',
           padding: '15px',
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
           fontFamily: 'Inter, system-ui, sans-serif',
           backgroundColor: '#1a1a1a', // Přidána tmavá barva pozadí
           marginBottom: '20px',
           marginTop: '20px',
           width: '100%', // Zajistí rozšíření tmavé barvy na celou šířku
           height: '100%', // Zajistí rozšíření tmavé barvy na celou výšku
       },
       iframe: {
           width: '100%',
           height: '75vh',
           maxWidth: '900px',
           border: 'none',
           borderRadius: '8px',
           filter: 'invert(0.9) hue-rotate(180deg)',
       }
   };

   return (
       <div style={styles.wrapper}>
           <iframe
               src="https://walletpayin.com/Whitepaper.pdf#toolbar=0"
               style={styles.iframe}
               title="Walletpayin Whitepaper"
           />
       </div>
   );
}

export default Whitepaper;