import React from 'react';
import './Token.css';

function Token() {
    const initialPriceUSD = ((2 * 1800) / (100000000 * 0.533)).toFixed(8);
    const initialMarketCapUSD = (100000000 * 0.533 * initialPriceUSD).toFixed(2);

    const oldTokenInfo = [
        { title: "Name", value: "WPAYIN" },
        { title: "Symbol", value: "WPI" },
        { title: "Network", value: "Ethereum (ETH)" },
        { title: "Token Type", value: "ERC-20" },
        { title: "Max Total Supply", value: "10,000,000,000,000 WPI" },
        { title: "Total Transfers", value: "527" },
        { title: "Current Price", value: "$0.0000000001795787921114856 USD" },
        { title: "Market Cap", value: "$1.79K USD" },
        { title: "Liquidity", value: "$1.1K USD" },
        {
            title: "Pair Address",
            value: "0xf716d309df919a2a22e3e4da19ff018f2f9b71e5",
            isLink: true,
            href: "https://www.dextools.io/app/en/ether/pair-explorer/0xf716d309df919a2a22e3e4da19ff018f2f9b71e5"
        },
        {
            title: "Contract Address",
            value: "0x5f25003218bf63ab578d918ab12ac1bf60e49c41",
            isLink: true,
            href: "https://etherscan.io/token/0x5f25003218bf63ab578d918ab12ac1bf60e49c41"
        }
    ];

    const newTokenInfo = [
        { title: "Name", value: "WPAYIN" },
        { title: "Symbol", value: "WPI" },
        { title: "Network", value: "Ethereum (ETH)" },
        { title: "Token Type", value: "ERC-20" },
        { title: "Contract Type", value: "Transparent & Verified" },
        { title: "Max Total Supply", value: "100,000,000 WPI" },
        { title: "Launch Allocation", value: "53.3% (53,300,000 WPI)" },
        { title: "Initial Price", value: `$${initialPriceUSD} USD` },
        { title: "Initial Market Cap", value: `$${initialMarketCapUSD} USD` },
        { title: "Initial Liquidity", value: "2 ETH" },
        {
            title: "Contract Address",
            value: "0x6d0075AcC99900b07544ccC9a9A3bF2001b0f808",
            isLink: true,
            href: "https://etherscan.io/token/0x6d0075AcC99900b07544ccC9a9A3bF2001b0f808"
        }
    ];

    const organizeTokenInfo = (info) => ({
        basic: info.filter(item => 
            ['Name', 'Symbol', 'Network', 'Token Type', 'Contract Type'].includes(item.title)
        ),
        supply: info.filter(item => 
            ['Max Total Supply', 'Launch Allocation', 'Total Transfers'].includes(item.title)
        ),
        market: info.filter(item => 
            ['Current Price', 'Initial Price', 'Market Cap', 'Initial Market Cap', 'Liquidity', 'Initial Liquidity'].includes(item.title)
        ),
        technical: info.filter(item => 
            ['Contract Address', 'Pair Address'].includes(item.title)
        )
    });

    return (
        <div className="token-wrapper">
            <div className="token-pattern"></div>
            <div className="token-container">
                <header className="token-header">
                    <h2 className="token-title">Token Migration Information</h2>
                    <p className="token-subtitle">
                        Compare the details of the old WPI token with the new version, including total supply, market cap, and other relevant metrics.
                    </p>
                </header>

                <div className="migration-banner">
                    <div className="migration-content">
                        <div className="migration-box">
                            <div className="migration-label">Old Token Supply</div>
                            <div className="migration-value">10T WPI</div>
                        </div>
                        <div className="migration-arrow">➜</div>
                        <div className="migration-box">
                            <div className="migration-label">New Token Supply</div>
                            <div className="migration-value">100M WPI</div>
                        </div>
                    </div>
                    <p className="migration-note">
                        We are upgrading to a new smart contract with improved tokenomics and enhanced security features. 
                        The total supply has been optimized from 10 trillion to 100 million tokens for better market efficiency 
                        and more sustainable growth.
                    </p>
                </div>

                <div className="token-grid">
                    <div className="token-column">
                        <div className="column-header">
                            <h3 className="column-title">
                                <span className="column-icon">🔄</span>
                                Old Token
                            </h3>
                        </div>
                        <div className="cards-container">
                            {Object.entries(organizeTokenInfo(oldTokenInfo)).map(([group, items]) => items.length > 0 && (
                                <div key={group} className="card-group">
                                    <h4 className="group-title">
                                        {group.charAt(0).toUpperCase() + group.slice(1)} Information
                                    </h4>
                                    {items.map((item, index) => (
                                        <div key={index} className="info-card">
                                            <h5 className="card-title">{item.title}</h5>
                                            {item.isLink ? (
                                                <a
                                                    href={item.href}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="card-link"
                                                >
                                                    {item.value}
                                                </a>
                                            ) : (
                                                <p className={`card-value ${item.highlight ? 'highlight' : ''}`}>
                                                    {item.value}
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="token-column">
                        <div className="column-header">
                            <h3 className="column-title">
                                <span className="column-icon">⭐</span>
                                New Token
                            </h3>
                        </div>
                        <div className="cards-container">
                            {Object.entries(organizeTokenInfo(newTokenInfo)).map(([group, items]) => items.length > 0 && (
                                <div key={group} className="card-group">
                                    <h4 className="group-title">
                                        {group.charAt(0).toUpperCase() + group.slice(1)} Information
                                    </h4>
                                    {items.map((item, index) => (
                                        <div key={index} className="info-card">
                                            <h5 className="card-title">{item.title}</h5>
                                            {item.isLink ? (
                                                <a
                                                    href={item.href}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="card-link"
                                                >
                                                    {item.value}
                                                </a>
                                            ) : (
                                                <p className={`card-value ${item.highlight ? 'highlight' : ''}`}>
                                                    {item.value}
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Token;