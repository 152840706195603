import React from 'react';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="navbar navbar-expand-lg header-navbar header-navbar--v2" data-aos="fade-down">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img width="150" src="../assets/media/images/logo-full.png" alt="Walletpayin" />
        </a>
        <button className="navbar-toggler ml-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <img src="../assets/media/images/icons/menu.svg" alt="MENU" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="navbar-collapse__content">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="/">{t('nav.about')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/token">{t('nav.token')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/roadmap">{t('nav.roadmap')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">{t('nav.news')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">{t('nav.partners')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact">{t('nav.contact')}</a>
              </li>
            </ul>
            <div className="navbar-buttons">
              <a href="https://app.walletpayin.com" className="btn btn-primary btn-sm">{t('nav.connect_wallet')}</a>
              <a href="https://walletpayin.com/Whitepaper" className="btn btn-secondary btn-sm">Whitepaper</a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
