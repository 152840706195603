import React from 'react';
import { useTranslation } from 'react-i18next';

function RoadMap() {
  const { t } = useTranslation();

  const sectionStyle = {
    backgroundImage: "url('../images/landing-page-pattern.svg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <section className="timeline_area section_padding_130" style={sectionStyle}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-lg-6">
            <h2 className="text-center feature__title ls-tight font-bolder display-3">
              {t('roadmap.text.roadMap')}
            </h2>
            <p className="text-center lead mb-5">
              {t('roadmap.text.ourCurrentAchievedGoalsAreTheVisionsWeGraduallyUnveil')}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="apland-timeline-area">

              {/* Q4 2024 Milestones */}
              <div className="single-timeline-area">
                <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
                  <p>{t('roadmap.text.Q4_2024')}</p>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-address-card" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.webAppDevelopment')}</h6>
                        <p>{t('roadmap.text.robustWebInterface')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-shield-alt" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.certikAudit')}</h6>
                        <p>{t('roadmap.text.securityAuditCompletion')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-bullhorn" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.marketingInitiatives')}</h6>
                        <p>{t('roadmap.text.raisingAwareness')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Q1 2025 Milestones */}
              <div className="single-timeline-area">
                <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
                  <p>{t('roadmap.text.Q1_2025')}</p>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-bolt" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.betaVersionLaunch')}</h6>
                        <p>{t('roadmap.text.betaSupportForMultipleChains')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-exchange-alt" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.uniswapListing')}</h6>
                        <p>{t('roadmap.text.listingWithLiquidity')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-users" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.ambassadorProgram')}</h6>
                        <p>{t('roadmap.text.expandingCommunity')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Q2 2025 Milestones */}
              <div className="single-timeline-area">
                <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
                  <p>{t('roadmap.text.Q2_2025')}</p>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.telegramMiniApp')}</h6>
                        <p>{t('roadmap.text.assetManagementInTelegram')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.cryptoEvents')}</h6>
                        <p>{t('roadmap.text.eventsInDubai')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Q3 2025 Milestones */}
              <div className="single-timeline-area">
                <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s">
                  <p>{t('roadmap.text.Q3_2025')}</p>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-laptop" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.browserApp')}</h6>
                        <p>{t('roadmap.text.accessibilityAndConvenience')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-gamepad" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.memeGame')}</h6>
                        <p>{t('roadmap.text.interactiveMemeGame')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s">
                      <div className="timeline-icon">
                        <i className="fa fa-coins" aria-hidden="true"></i>
                      </div>
                      <div className="timeline-text">
                        <h6>{t('roadmap.text.presaleOfMemeTokens')}</h6>
                        <p>{t('roadmap.text.expandEcosystem')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RoadMap;
