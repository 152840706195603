import React from 'react';
import { useTranslation } from 'react-i18next';

const CTASection = () => {
  const { t } = useTranslation();

  return (
    <section className="cta">
      <div className="cta__background cta__background--pattern">
        <img className="cta__pattern-bg" src="../assets/media/images/landing-page-v2/landing-page-v2-cta-pattern.png" alt="Background Pattern CTA" />
      </div>
      <div className="container">
        <div className="cta__container text-center">
          <p className="fd-sm cta__subtitle">{t('cta.subtitle')}</p>
          <h3 className="cta__title">{t('cta.title')}</h3>
          <form className="mini-form">
            <input type="text" className="form-control mini-form__input" placeholder={t('cta.placeholder')} autocomplete="off" />
            <input type="submit" className="btn btn-primary mini-form__submit" value={t('cta.button')} />
          </form>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
