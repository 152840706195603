import React from 'react';
import { useTranslation } from 'react-i18next';

const TestimonialSection = () => {
  const { t } = useTranslation();

  return (
    <section className="testimonial">
      <div className="container">
        <div className="testimonial__container">
          <h2 className="testimonial__title">{t('testimonial.title')}</h2>
          <div className="testimonial__content" data-aos="fade-up" data-aos-delay="100">
            <div className="testimonial__item">
              <p className="fb-regular">{t('testimonial.quote1')}</p>
              <p className="fb-bold">{t('testimonial.author1')}</p>
            </div>
            <div className="testimonial__item">
              <p className="fb-regular">{t('testimonial.quote2')}</p>
              <p className="fb-bold">{t('testimonial.author2')}</p>
            </div>
            <div className="testimonial__item">
              <p className="fb-regular">{t('testimonial.quote3')}</p>
              <p className="fb-bold">{t('testimonial.author3')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
