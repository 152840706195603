import React from 'react';

function Privacy() {
    const lastUpdated = "September 9, 2023";
    
    const styles = {
        wrapper: {
            minHeight: '100vh',
            padding: '40px 20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Inter, system-ui, sans-serif'
        },
        container: {
            maxWidth: '800px',
            width: '100%',
            borderRadius: '16px',
            padding: '40px',
            backdropFilter: 'blur(10px)',
        },
        header: {
            marginBottom: '40px',
            textAlign: 'center'
        },
        title: {
            fontSize: '2.5rem',
            fontWeight: '700',
            background: 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            marginBottom: '16px'
        },
        content: {
            color: '#e5e7eb',
            fontSize: '1.1rem',
            lineHeight: '1.8'
        },
        paragraph: {
            marginBottom: '24px'
        },
        listContainer: {
            background: 'rgba(59, 130, 246, 0.05)',
            borderRadius: '12px',
            padding: '24px 32px',
            margin: '32px 0',
            border: '1px solid rgba(59, 130, 246, 0.1)'
        },
        list: {
            listStyle: 'none',
            padding: 0,
            margin: 0
        },
        listItem: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px',
            color: '#93c5fd',
            fontSize: '1.05rem'
        },
        bullet: {
            width: '8px',
            height: '8px',
            backgroundColor: '#3b82f6',
            borderRadius: '50%',
            marginRight: '16px',
            flexShrink: 0
        },
        footer: {
            marginTop: '48px',
            paddingTop: '24px',
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            textAlign: 'center',
            color: '#9ca3af',
            fontSize: '0.875rem'
        }
    };

    return (
        <div style={styles.wrapper}>
            <div style={styles.container}>
                <header style={styles.header}>
                    <h2 style={styles.title}>Privacy Policy</h2>
                </header>
                
                <div style={styles.content}>
                    <p style={styles.paragraph}>
                        Thank you for visiting the website for the Walletpayin app.
                    </p>
                    
                    <p style={styles.paragraph}>
                        The Walletpayin app doesn't collect any data or use analytics tools that may expose any data about its users. 
                        The wallet is designed to ensure a high level of privacy for its users.
                    </p>
                    
                    <div style={styles.listContainer}>
                        <ul style={styles.list}>
                            {[
                                "User data always remains on the user's device.",
                                "The wallet doesn't collect any data about users.",
                                "The wallet doesn't share any data about users.",
                                "There are no user accounts or databases keeping user data elsewhere."
                            ].map((text, index) => (
                                <li key={index} style={styles.listItem}>
                                    <span style={styles.bullet}></span>
                                    {text}
                                </li>
                            ))}
                        </ul>
                    </div>
                    
                    <p style={styles.paragraph}>
                        This website neither collects any data about its users nor employs any third-party analytics 
                        or data collecting services.
                    </p>
                </div>
                
                <footer style={styles.footer}>
                    Last Updated: {lastUpdated}
                </footer>
            </div>
        </div>
    );
}

export default Privacy;