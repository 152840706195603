import React from 'react';
import { useTranslation } from 'react-i18next';

const WhyChooseUsSection = () => {
  const { t } = useTranslation();

  return (
    <section className="why-choose-us why-choose-us--v2">
      <div className="why-choose-us__background why-choose-us__background--pattern">
        <img className="why-choose-us__pattern-bg" src="/assets/media/images/landing-page-v2/landing-page-v2-why-choose-us-pattern.png" alt="Background Pattern why-choose-us" />
      </div>
      <div className="why-choose-us__background--mobile why-choose-us__background--pattern">
        <img className="why-choose-us__pattern-bg" src="/assets/media/images/landing-page-v2/mobile-landing-page-v2-why-choose-us-pattern.png" alt="Background Pattern Why Choose Us" />
      </div>
      <div className="container">
        <div className="why-choose-us__container">
          <div className="why-choose-us__content">
            <h2 className="why-choose-us__title" data-aos="fade-up">{t('why_choose_us.title')}</h2>
            <p className="fb-regular why-choose-us__subtitle" data-aos="fade-up" data-aos-delay="100">{t('why_choose_us.subtitle')}</p>
            <div className="why-choose-us__groups">
              <div className="why-choose-us__items" data-aos="fade-left" data-aos-delay="400">
                <div className="why-choose-us-items__header">
                  <img className="why-choose-us-items__icon" src="/assets/media/images/icons/eyes.svg" alt="Clarity" />
                </div>
                <div className="why-choose-us-items__content">
                  <h5 className="why-choose-us-item__title">{t('why_choose_us.security_title')}</h5>
                  <p className="fb-regular why-choose-us-item__desc">{t('why_choose_us.security_desc')}</p>
                </div>
              </div>
              <div className="why-choose-us__items" data-aos="fade-left" data-aos-delay="500">
                <div className="why-choose-us-items__header">
                  <img className="why-choose-us-items__icon" src="/assets/media/images/icons/shield-done.svg" alt="Confidence" />
                </div>
                <div className="why-choose-us-items__content">
                  <h5 className="why-choose-us-item__title">{t('why_choose_us.support_title')}</h5>
                  <p className="fb-regular why-choose-us-item__desc">{t('why_choose_us.support_desc')}</p>
                </div>
              </div>
              <div className="why-choose-us__items" data-aos="fade-left" data-aos-delay="600">
                <div className="why-choose-us-items__header">
                  <img className="why-choose-us-items__icon" src="/assets/media/images/icons/community.svg" alt="Community" />
                </div>
                <div className="why-choose-us-items__content">
                  <h5 className="why-choose-us-item__title">{t('why_choose_us.community_title')}</h5>
                  <p className="fb-regular why-choose-us-item__desc">{t('why_choose_us.community_desc')}</p>
                </div>
              </div>
            </div>
          </div>
          <img className="why-choose-us__illustration" data-aos="zoom-in-up" data-aos-delay="300" src="/assets/media/images/landing-page-v2/landing-page-v2-why-choose-us-illustration.svg" alt="" />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsSection;
