import React from 'react';

function Terms() {
    const lastUpdated = "September 9, 2023";
    
    const styles = {
        wrapper: {
            minHeight: '100vh',
            padding: '40px 20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            fontFamily: 'Inter, system-ui, sans-serif'
        },
        container: {
            maxWidth: '900px',
            width: '100%',
            borderRadius: '16px',
            padding: '40px',
            backdropFilter: 'blur(10px)',
            color: '#e5e7eb'
        },
        header: {
            marginBottom: '40px',
            textAlign: 'center'
        },
        title: {
            fontSize: '2.5rem',
            fontWeight: '700',
            background: 'linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            marginBottom: '16px'
        },
        section: {
            marginBottom: '32px',
            padding: '24px',
            background: 'rgba(255, 255, 255, 0.02)',
            borderRadius: '12px',
            border: '1px solid rgba(255, 255, 255, 0.05)'
        },
        sectionTitle: {
            fontSize: '1.5rem',
            fontWeight: '600',
            color: '#60a5fa',
            marginBottom: '16px'
        },
        paragraph: {
            marginBottom: '16px',
            lineHeight: '1.8',
            fontSize: '1.05rem'
        },
        list: {
            listStyle: 'none',
            padding: '0',
            margin: '16px 0'
        },
        listItem: {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '12px',
            paddingLeft: '24px',
            position: 'relative'
        },
        bullet: {
            position: 'absolute',
            left: '0',
            top: '12px',
            width: '6px',
            height: '6px',
            backgroundColor: '#3b82f6',
            borderRadius: '50%'
        },
        footer: {
            marginTop: '48px',
            paddingTop: '24px',
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            textAlign: 'center',
            color: '#9ca3af',
            fontSize: '0.875rem'
        },
        link: {
            color: '#60a5fa',
            textDecoration: 'none',
            transition: 'color 0.2s'
        },
        emphasis: {
            color: '#60a5fa',
            fontWeight: '500'
        }
    };

    const renderSection = (title, content) => (
        <div style={styles.section}>
            <h4 style={styles.sectionTitle}>{title}</h4>
            {content}
        </div>
    );

    const renderParagraph = (text) => (
        <p style={styles.paragraph}>{text}</p>
    );

    const renderList = (items) => (
        <ul style={styles.list}>
            {items.map((item, index) => (
                <li key={index} style={styles.listItem}>
                    <span style={styles.bullet}></span>
                    {item}
                </li>
            ))}
        </ul>
    );

    return (
        <div style={styles.wrapper}>
            <div style={styles.container}>
                <header style={styles.header}>
                    <h2 style={styles.title}>Terms of Service</h2>
                </header>

                {renderSection("Agreement to Terms",
                    renderParagraph("By accessing or using any or all of the Services provided by Walletpayin, you expressly acknowledge that (i) you have read and understood these Terms; (ii) you agree to be bound by these Terms; and (iii) you are legally competent to enter into these Terms. If you do not agree to be bound by these Terms or any updates or modifications to these Terms, you may not access or use our Services.")
                )}

                {renderSection("Privacy Policy",
                    renderParagraph("For an explanation of how we collect, use and disclose information from our users, please see our Privacy Policy at [insert link to your Privacy Policy]. You acknowledge and agree that your use of the Services is subject to, and that we can collect, use and/or disclose your information (including any personal data you provide to us) in accordance with our Privacy Policy.")
                )}

                {renderSection("Updates to Terms or Services",
                    renderParagraph("We reserve the right to update or modify these Terms at any time at our sole discretion. If we do so, we'll let you know by either posting the revised Terms on our website, on our mobile application or through other methods of communication which we deem reasonable. Such revised Terms as posted will take effect immediately, unless otherwise indicated. You should regularly check our website to inform yourself of any such changes and decide whether or not to accept the revised version of these Terms. If you continue to use Walletpayin following any update or modification of the Terms you shall be deemed to have accepted the revised Terms. If you do not agree to the Terms or any update or modification to the Terms, you must cease to access or use our Services.")
                )}

                {renderSection("Eligibility", <>
                    {renderParagraph("To be eligible to use Walletpayin (i) you must be at least eighteen (18) years old and legally competent to enter into these Terms; (ii) you must not be a resident of sanctioned jurisdictions according to any trade embargoes, UN Security Council Resolutions (\"UNSCR\") or HM Treasury's financial sanctions regime; and (iii) you must not be currently the subject of or subject to economic sanctions such as the United Nations Security Council Sanctions List, the list of specially designated nationals maintained by OFAC, the denied persons or entity list of the U.S. Department of Commerce or any similar list maintained by any other relevant sanctions authority.")}
                    {renderParagraph("If you are using our Services on behalf of a legal entity, you further represent and warrant that (iv) the legal entity is duly organized and validly existing under the applicable laws of the jurisdiction of its organization; and (v) you are duly authorized by such legal entity to act on its behalf.")}
                    {renderParagraph("You can only use our Services if permitted under the laws of your jurisdiction. For the avoidance of doubt, you may not use our Services if you are located in, or a citizen or resident of any state, country, territory or other jurisdiction where your use of our Services would be illegal or otherwise violate any applicable laws.")}
                </>)}

                {renderSection("Our Services", <>
                    {renderParagraph("Walletpayin is a cutting-edge non-custodial wallet software designed for managing a range of digital assets including cryptocurrencies, virtual commodities, and NFTs (\"Digital Assets\"). This empowers you with complete control and responsibility over your Digital Assets and private keys, enabling you to authorize transactions directly from your wallet address.")}
                    {renderParagraph("With Walletpayin, you can:")}
                    {renderList([
                        "create wallet addresses and their corresponding private keys for sending and receiving digital assets",
                        "explore and access third-party decentralized applications (\"DexWallet\") and decentralized exchanges (\"DEX\") seamlessly through the mobile application's integrated web browser",
                        "exchange/trade digital assets using WalletApp functionality facilitated by third-party service provider(s)",
                        "participate in staking certain digital assets within a third-party 'proof of stake' network through our Staking Service",
                        "view up-to-date digital asset price information made available by third-party service provider(s)",
                        "efficiently broadcast Digital Asset Transaction data to various supported blockchains without requiring you to download or install any associated blockchain-based software on your local device"
                    ])}
                </>)}

                {renderSection("Wallet Address, Private Key, and Backup Capabilities",
                    renderParagraph("Walletpayin offers an encrypted backup option for specific information linked to your wallet, which can be securely stored on compatible devices. The private key, in conjunction with the wallet address, empowers you to authorize the transfer of Digital Assets to and from that specific wallet address. Safeguarding your private key and any mnemonic phrase (\"Secret Phrase\") associated with your wallet is your exclusive responsibility. Ensure the security of your wallet address, Secret Phrase, and private key access details. It is imperative that you create backups of your private keys, backup phrases, or passwords. Failure to do so may lead to the loss of control over Digital Assets connected to your wallet.")
                )}

                {renderSection("Utilizing DexWallet and DEX", <>
                    {renderParagraph("Should you choose to access or use DexWallet or DEX, including, but not limited to, WalletApp functionality integrated within our Services such as the Trade/Swap Digital Assets feature, kindly acknowledge and understand the following:")}
                    {renderList([
                        "Walletpayin is not liable for your access or use of DexWallet or DEX and assumes no responsibility whatsoever in connection with your utilization of DexWallet or DEX",
                        "the limits on the amounts you may exchange via DEX per day are subject to the requirements of third-party developed Smart Contracts",
                        "blockchain operations are irreversible, meaning you are solely accountable for the consequences of any issues associated with such transactions",
                        "third-party developed Smart Contracts may impose handling fees and/or service fees",
                        "Walletpayin currently does not impose any service fees or handling fees for the use of DexWallet or DEX"
                    ])}
                </>)}

                {renderSection("Utilizing Staking Services",
                    renderParagraph("Should you choose to engage with the Staking Service, a third party may handle the staking of specific Digital Assets on your behalf, acting as a transaction validator within the applicable blockchain network. If a block of transactions is successfully validated using these staked Digital Assets, you may receive a reward from the network (referred to as a \"Staking Reward\"). Please understand and acknowledge that Walletpayin (i) assumes no responsibility for your use of the Staking Service and bears no liability in connection with it; (ii) cannot guarantee uninterrupted staking services; and (iii) cannot guarantee that any particular Digital Asset will be staked or continue to be staked.")
                )}

                {renderSection("Acquiring Digital Assets", <>
                    {renderParagraph("If you opt to procure Digital Assets using your credit or debit card through Walletpayin, a third party will facilitate the conversion of your selected amount into the relevant Digital Asset, which will then be credited to your wallet. Please acknowledge and agree that:")}
                    {renderList([
                        "Walletpayin assumes no liability for your use of such third-party service",
                        "you will be subject to any terms and conditions set forth by the relevant third-party service provider",
                        "Walletpayin lacks the ability to modify, withdraw, or cancel any conversion requests initiated by you",
                        "Walletpayin is not responsible for any exchange rates provided by the relevant third-party service provider(s)",
                        "Walletpayin assumes no responsibility for any fees charged by the relevant third-party service provider(s), your bank, or your credit card issuer(s)"
                    ])}
                </>)}

                {renderSection("Digital Asset Transactions",
                    renderParagraph("In order for any proposed Digital Asset transactions to be finalized, they must be confirmed and recorded within the associated public blockchain of the respective Digital Asset. These networks are decentralized, peer-to-peer networks supported by independent third parties, over which we have no ownership, control, or operation. We exert no authority over these blockchain networks and, consequently, cannot ensure the confirmation and processing of any transaction details you submit through our Services.")
                )}

                {renderSection("Intellectual Property", <>
                    {renderParagraph("License: As long as you agree to and comply with the present Terms, we grant you a non-exclusive, non-sublicensable and non-transferable license to use Walletpayin for your personal use or internal business use only.")}
                    {renderParagraph("You will not:")}
                    {renderList([
                        "reproduce, modify, adapt or create derivative works of any part of Walletpayin",
                        "rent, lease, distribute, sell, sublicense, transfer, or provide access to Walletpayin",
                        "use Walletpayin for the benefit of any third party"
                    ])}
                    {renderParagraph("Feedback: From time to time, you may choose to submit feedback to us. We may, in connection with Walletpayin, freely use, copy, disclose, license, distribute and exploit any feedback in any manner without any obligation, royalty or restriction based on intellectual property rights or otherwise.")}
                </>)}

                {renderSection("Your Use of Walletpayin", <>
                    {renderParagraph("By using Walletpayin, you agree to abide by all applicable laws. These laws govern your use of our Services, and you are prohibited from using Walletpayin to facilitate, support, or engage in any illegal activities.")}
                    {renderParagraph("You agree that you will not:")}
                    {renderList([
                        "Encourage or induce any third party to engage in prohibited activities",
                        "Impersonate another individual or attempt to use someone else's wallet without proper authorization",
                        "Disrupt or interfere with other users' enjoyment of Walletpayin",
                        "Distribute viruses or malicious computer code via Walletpayin",
                        "Engage in actions that could overload or harm our infrastructure",
                        "Attempt to reverse-engineer our systems",
                        "Violate the rights of others, including privacy and intellectual property rights"
                    ])}
                </>)}

                {renderSection("Limitation of Liability", <>
                    {renderParagraph("You recognize and agree that we have no control over and are not responsible for:")}
                    {renderList([
                        "Failures, disruptions, errors, or delays in processing Digital Assets",
                        "Risks associated with hardware, software, and internet connectivity failures",
                        "Risks related to malicious software",
                        "Risks of unauthorized access to your wallet",
                        "Risks of unknown vulnerabilities in blockchain networks"
                    ])}
                    {renderParagraph("To the maximum extent permitted by applicable law, we, our affiliates, shareholders, directors, officers, employees, attorneys, agents, representatives, suppliers, and contractors shall not be liable for any direct or indirect damages or liabilities of any kind.")}
                </>)}

                {renderSection("Warranty Disclaimer",
                    renderParagraph("Walletpayin is provided \"as is\" and \"as available\", and without warranty of any kind. To the maximum extent permitted by law, we disclaim all representations and warranties, express or implied, relating to the services and underlying software or any aspect of the information, content, or the services.")
                )}

                {renderSection("Indemnity",
                    renderParagraph("To the extent permitted by applicable law, you agree to defend, indemnify, and hold harmless us, our affiliates, and our respective shareholders, members, directors, officers, employees, attorneys, agents, representatives, suppliers and contractors, from and against any and all claims, damages, obligations, losses, liabilities, tort, costs or debt, and expenses.")
                )}

                {renderSection("Governing Law",
                    renderParagraph("No matter where you're located, the laws of the State of California will govern these Terms and the parties' relationship as if you signed these Terms in California, without regard to California's conflicts of laws rules.")
                )}

                {renderSection("Questions or Comments", <>
                    {renderParagraph("If you have any questions regarding these Terms, your rights and obligations arising from these Terms, your use of Walletpayin and our Services, or any other matter, please send us a message on our contact page at:")}
                    <a href="mailto:support@walletpayin.com" style={styles.link}>support@walletpayin.com</a>
                </>)}

                <footer style={styles.footer}>
                    Last Updated: {lastUpdated}
                </footer>
            </div>
        </div>
    );
}

export default Terms;